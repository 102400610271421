import immutable from "seamless-immutable";
import {
  FETCH_BURGERPROFIEL_CONFIGURATION_ERROR,
  FETCH_BURGERPROFIEL_CONFIGURATION_RESPONSE,
  FETCH_DOSSIER_CREATION_TOGGLES_RESPONSE,
} from "./actions";

export const burgerprofielConfiguration = (
  state = immutable({ loaded: false }),
  action
) => {
  switch (action.type) {
    case FETCH_BURGERPROFIEL_CONFIGURATION_RESPONSE:
      return immutable({ loaded: true, configuration: action.response.data });
    case FETCH_BURGERPROFIEL_CONFIGURATION_ERROR:
      return immutable({ loaded: true });
    default:
      return state;
  }
};

export const dossierCreationToggles = (state = immutable({}), action) => {
  switch (action.type) {
    case FETCH_DOSSIER_CREATION_TOGGLES_RESPONSE:
        return immutable(action.response.data);
    default:
      return state;
  }
};
