import React, { useEffect, useState } from "react";
import { postscribePromise } from "../../services/postscribe";

import CoreHeader from "@skryv/core-react-vo/src/components/layout/Header/Header";
import { useDispatch, useSelector } from "react-redux";

import { loginUser } from "@skryv/core-react/src/core/store/actions/authentication";
import { isAuthenticated } from "@skryv/core-react/src/core/store/selectors/users";

import { fetchBurgerprofielConfiguration } from "../../store/actions";
import {
  hasLoadedBurgerprofiel,
  hasValidBurgerprofielHeader,
  selectBurgerprofielHeader,
} from "../../store/selectors";

export default function WrpHeader(props) {
  const HEADER_SELECTOR = "header-script";
  const dispatch = useDispatch();
  const [shouldShowProfile, setShouldShowProfile] = useState(false);

  useEffect(() => {
    dispatch(loginUser());
    dispatch(fetchBurgerprofielConfiguration());
  }, [dispatch]);

  const hasActiveSession = useSelector(isAuthenticated);

  const hasLoadedConfiguration = useSelector(hasLoadedBurgerprofiel);
  const hasValidConfiguration = useSelector(hasValidBurgerprofielHeader);
  const embedCode = useSelector(selectBurgerprofielHeader);
  const hasRenderedHeader = () => {
    const headerElement = document.getElementById("header-script");
    return headerElement && headerElement.children.length > 0;
  };
  const loadBurgerprofielHeader = (embedCode, hasActiveSession) => {
    const burgerprofielPolyfillSrc =
      "https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js";
    const burgerprofielClientSrc =
      "https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-client/dist/index.js";

    return Promise.all([
      postscribePromise(`#${HEADER_SELECTOR}`, burgerprofielPolyfillSrc),
      postscribePromise(`#${HEADER_SELECTOR}`, burgerprofielClientSrc),
    ])
      .then(() => postscribePromise(`#${HEADER_SELECTOR}`, embedCode))
      .then(() => {
        window.vl.widget.client.capture((widget) => {
          widget.getExtension("citizen_profile.session").then((session) => {
            session.configure({
              active: hasActiveSession,
              endpoints: {
                loginUrl: "/api/login/frontoffice",
                logoutUrl: "/api/logout",
                switchCapacityUrl: "/api/wisselen-van-account",
              },
            });
          });
        });
      });
  };

  useEffect(() => {
    if (!hasActiveSession) return;
    if (!hasLoadedConfiguration) return;
    if (hasRenderedHeader()) return;
    if (!hasValidConfiguration) {
      setShouldShowProfile(true);
      return;
    }

    loadBurgerprofielHeader(embedCode, hasActiveSession);
  }, [
    hasLoadedConfiguration,
    hasValidConfiguration,
    embedCode,
    hasActiveSession,
  ]);

  return (
    <CoreHeader
      {...props}
      title={"dMOW - "}
      subtitle={'Plaatsing Publieke Laadpalen'}
      shouldShowProfile={shouldShowProfile}
    ></CoreHeader>
  );
}
