import {
  SkryvFrontOfficeApp,
  navigation,
  translation,
  form,
} from "@skryv/core-react";
import '@skryv/core-react-vo';
import { registerDefaultRouting } from '@skryv/core-react-vo/src/config/routing';
import { registerDefaultTranslations } from '@skryv/core-react-vo/src/config/translations';

import translations from '../translations';
import "./theme/overrides.scss";

import customReduxStore from "./store";

import Header from "./customizations/components/Header";
import Footer from "./customizations/components/Footer";
import Dashboard from "./customizations/components/Dashboard";
import LaadPalenDossier from "./customizations/components/dossier/LaadPalenDossier";

import { layoutNames } from '@skryv/core-react/src/config/layouts';
import { pageNames } from '@skryv/core-react/src/config/pages';

const customizedNavigation = registerDefaultRouting(navigation)
  .layout(layoutNames.FRONTOFFICE, {
    extend: true,
    views: {
      header: Header,
      footer: Footer
    }
  })
  .page(pageNames.DASHBOARD, {
    extend: true,
    views: {
      content: Dashboard
    }
  })
  .page(pageNames.DOSSIER, {
    extend: true,
    views: {
      content: LaadPalenDossier
    }
  });

const customizedTranslation = registerDefaultTranslations(translation)
  .locale('nl_BE') // set the language for the core components
  .add('nl_BE',translations.nl_BE); // make sure the custom client translations are also available

form.setFormConfig({
  showSectionWizard: true,
  showDocumentTitle: false,
  attachments: {
    acceptedMimeTypes: ['pdf']
  }
});

class App extends SkryvFrontOfficeApp {
  constructor() {
    super(customReduxStore);
  }
}

App.createSkryvApp()
  .customize(customizedTranslation)
  .customize(form)
  .customize(customizedNavigation)
  .initialize(document.getElementById('root'));
