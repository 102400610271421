import React from 'react';
import { get } from 'lodash';
import moment from 'moment';

import contextType from '@skryv/core-react/src/services/contextTypes';
import { dossierPageWrapper as coreDossierWrapper, externalProps, internalProps, defaultProps } from '@skryv/core-react/src/components/dossier/DossierPage/DossierPage';
import { DossierPage as CoreDossierPage } from '@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage';

import contactInformation from '../../constants/contactInformation';

import documentDefinitionkeys from '../../constants/documentDefinitionKeys';
import taskDefinitionKeys from '../../constants/taskDefinitionKeys';

export function mowPvwSubDossierWrapper() {
  class mowPvwSubDossier extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    constructor(props) {
      super(props);

      // the contact information is static and will not change, we can set it here
      // the other state properties depend on the dossier state an will be set in the componentDidUpdate
      // for now we set them equal to the props, which contain the default values
      this.state = {
        contactInformation,
        dossierInformation: this.props.dossierInformation,
        downloads: this.props.downloads,
        notifications: this.props.notifications
      };

      this.getDossierInformation = this.getDossierInformation.bind(this);
      this.getDownloads = this.getDownloads.bind(this);
      this.getNotifications = this.getNotifications.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.props.dossierDetails !== prevProps.dossierDetails) {
        // the props have just been updated; so either a parent updated the props or new values came in through redux
        this.setState({
          dossierInformation: this.getDossierInformation(),
          downloads: this.getDownloads(),
          notifications: this.getNotifications()
        });
      }
    }

    getDossierInformation() {
      const dossierInformation = [];
      if(this.props.dossier) {
        dossierInformation.push({
          value: get(this.props.dossier,'label'),
          label: this.context.gettext('Dossier label')
        });
        dossierInformation.push({
          value: moment(get(this.props.dossier,'updatedAt')).format('DD-MM-YYYY'),
          label: this.context.gettext('Last update')
        });
      } 
      return dossierInformation;
    }

    getDownloads() {
      if (!this.props.dossierDetails) return [];
      const downloads = [];
      if(this.props.getDocument(documentDefinitionkeys.PVW_ADVIES, true)){
        downloads.push(this.props.mapTaskToViewDownloadInfo(this.props.getTask(taskDefinitionKeys.PVW_ADVIES_TAAK),'Adviesformulier'));
      }
      return downloads;
    }

    getNotifications() {
      let notifications = [];
      return notifications;
    }

    render() {
      // override some default props with props that are derived from latest version of the dossierDetails
      const componentsToPass = {
        ...this.props,
        contactInformation: this.state.contactInformation,
        dossierInformation: this.state.dossierInformation,
        downloads: this.state.downloads,
        notifications: this.state.notifications
      };

      return <CoreDossierPage { ...componentsToPass }/>;
    }
  }

  return mowPvwSubDossier;
}

export default coreDossierWrapper(mowPvwSubDossierWrapper());
