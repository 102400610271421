import React from "react";
import { get } from "lodash";

import {
  dossierPageWrapper as coreDossierPageWrapper,
  externalProps,
  internalProps,
  defaultProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";

import dossierDefinitionKeys from "../../constants/dossierDefinitionKeys";
import StratPlaatsingDossier from "./StrategischePlaatsingDossier";
import PvwDossier from "./PaalVolgtWagenDossier";
import PvwSubDossier from "./PaalVolgtWagenSubDossier";

import contextType from "@skryv/core-react/src/services/contextTypes";

export function mowDossierPageWrapper() {
  class mowDossierPage extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    render() {
      const dossierDefinitionName = get(
        this.props.dossierDetails,
        ["dossier", "dossierDefinition", "key"],
        ""
      );
      switch (dossierDefinitionName) {
        case dossierDefinitionKeys.PVW:
          return <PvwDossier {...this.props} />;
        case dossierDefinitionKeys.STRATPLAATSING:
          return <StratPlaatsingDossier {...this.props} />;
        case dossierDefinitionKeys.PVW_SUB:
          return <PvwSubDossier {...this.props} />;
        default:
          return null;
      }
    }
  }

  return mowDossierPage;
}

export default coreDossierPageWrapper(mowDossierPageWrapper());
