import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

export function selectBurgerprofielHeader(state) {
  return get(state, [
    "burgerprofielConfiguration",
    "configuration",
    "headerEmbedCode",
  ]);
}

export function selectBurgerprofielFooter(state) {
  return get(state, [
    "burgerprofielConfiguration",
    "configuration",
    "footerEmbedCode",
  ]);
}

export function hasLoadedBurgerprofiel(state) {
  return get(state, ["burgerprofielConfiguration", "loaded"]);
}

export function hasValidBurgerprofielFooter(state) {
  return !isEmpty(
    get(state, [
      "burgerprofielConfiguration",
      "configuration",
      "footerEmbedCode",
    ])
  );
}

export function hasValidBurgerprofielHeader(state) {
  return !isEmpty(
    get(state, [
      "burgerprofielConfiguration",
      "configuration",
      "headerEmbedCode",
    ])
  );
}

export function isAbleToCreateStrategischePlaatsingDossiers(state) {
  return get(state, [ "dossierCreationToggles", "ableToCreateStrategischePlaatsingDossiers" ], false)
}

export function isAbleToCreatePaalVolgtWagenDossiers(state) {
  return get(state, [ "dossierCreationToggles", "ableToCreatePaalVolgtWagenDossiers" ], false)
}