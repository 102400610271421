export default {
    title: 'Departement Mobiliteit en Openbare Werken',
    contact: 'https://www.mow-contact.be/',
    address: {
      buildingName: 'Graaf de Ferrarisgebouw',
      streetAndNumber: 'Koning Albert II-laan 20 bus 2',
      zipCode: '1000',
      city: 'Brussel',
      country: 'België'
    },
    phone: '1700',
    lat: '50.86416667',
    long: '4.35861111',
    mapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2518.2208468705753!2d4.356529615746438!3d50.864111079534595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c39e65186d4d%3A0x74ba6759673fc88d!2sBld%20du%20Roi%20Albert%20II%2020%2Fbus%202%2C%201000%2C%201000%2C%201000%20Bruxelles!5e0!3m2!1sen!2sbe!4v1596524567017!5m2!1sen!2sbe'
  };