import React from "react";

import { connect } from "react-redux";

import { fetchDossierCreationToggles } from "../../store/actions";
import {
  isAbleToCreatePaalVolgtWagenDossiers,
  isAbleToCreateStrategischePlaatsingDossiers,
} from "../../store/selectors";

import CoreDashboardPage from "@skryv/core-react-vo/src/pages/Dashboard/DashboardPage";
import contactInformation from "../constants/contactInformation";

import {
  defaultProps,
  externalProps,
  internalProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import contextType from "@skryv/core-react/src/services/contextTypes";

const STRATEGISCHE_PLAATSING_DOSDEF_KEY = "strategischePlaatsing";
const PAAL_VOLGT_WAGEN_DOSDEF_KEY = "pvwHoofdDossier";

class Dashboard extends React.Component {
  static propTypes = { ...externalProps, ...internalProps };
  static defaultProps = defaultProps;
  static contextType = contextType;

  constructor(props) {
    super(props);

    props.fetchDossierCreationToggles();

    this.state = {
      contactInformation,
      title: "Plaatsing Publieke Laadpalen",
    };
  }

  render() {
    return (
      <CoreDashboardPage {...this.state} createDossierInformation={ this.props.createDossierInformation }></CoreDashboardPage>
    );
  }
}

const mapStateToProps = (state) => {
  const canCreatePaalVolgtWagenDossiers =
    isAbleToCreatePaalVolgtWagenDossiers(state);
  const canCreateStrategischePlaatsingDossiers =
    isAbleToCreateStrategischePlaatsingDossiers(state);

  let createDossierInformation = [];

  if (canCreatePaalVolgtWagenDossiers) {
    createDossierInformation = [
      {
        dosdefKey: PAAL_VOLGT_WAGEN_DOSDEF_KEY,
        buttonLabels: {
          active: "Aanvraag indienen",
        },
      },
    ];
  }

  if (canCreateStrategischePlaatsingDossiers) {
    createDossierInformation = [
      {
        dosdefKey: STRATEGISCHE_PLAATSING_DOSDEF_KEY,
        buttonLabels: {
          active: "Aanvraag indienen",
        },
      },
    ];
  }

  return { createDossierInformation };
};

const mapDispatchToProps = {
  fetchDossierCreationToggles,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
