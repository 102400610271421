import React from 'react';
import { get } from 'lodash';
import moment from 'moment';

import contextType from '@skryv/core-react/src/services/contextTypes';
import { dossierPageWrapper as coreDossierWrapper, externalProps, internalProps, defaultProps } from '@skryv/core-react/src/components/dossier/DossierPage/DossierPage';
import { notificationTypes } from '@skryv/core-react/src/components/base/Notification/Notification';
import { DossierPage as CoreDossierPage } from '@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage';

import contactInformation from '../../constants/contactInformation';

import documentDefinitionkeys from '../../constants/documentDefinitionKeys';
import taskDefinitionKeys from '../../constants/taskDefinitionKeys';
import milestoneDefinitionKeys from  '../../constants/milestoneDefinitionKeys';

export function mowPvwDossierWrapper() {
  class mowPvwDossier extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    constructor(props) {
      super(props);

      // the contact information is static and will not change, we can set it here
      // the other state properties depend on the dossier state an will be set in the componentDidUpdate
      // for now we set them equal to the props, which contain the default values
      this.state = {
        contactInformation,
        dossierInformation: this.props.dossierInformation,
        downloads: this.props.downloads,
        notifications: this.props.notifications
      };

      this.getDossierInformation = this.getDossierInformation.bind(this);
      this.getDownloads = this.getDownloads.bind(this);
      this.getNotifications = this.getNotifications.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.props.dossierDetails !== prevProps.dossierDetails) {
        // the props have just been updated; so either a parent updated the props or new values came in through redux
        this.setState({
          dossierInformation: this.getDossierInformation(),
          downloads: this.getDownloads(),
          notifications: this.getNotifications()
        });
      }
    }

    getDossierInformation() {
      const dossierInformation = [];
      if(this.props.dossier) {
        dossierInformation.push({
          value: get(this.props.dossier,'label'),
          label: this.context.gettext('Dossier label')
        });
        dossierInformation.push({
          value: moment(get(this.props.dossier,'updatedAt')).format('DD-MM-YYYY'),
          label: this.context.gettext('Last update')
        });
      } 
      return dossierInformation;
    }

    getDownloads() {
      if (!this.props.dossierDetails) return [];
      const downloads = [];
      if(this.props.getDocument(documentDefinitionkeys.PVW_AANVRAAG, true)){
        downloads.push(this.props.mapTaskToViewDownloadInfo(this.props.getTask(taskDefinitionKeys.PVW_AANVRAAG_TAAK),'Aanvraagformulier'));
      }
      return downloads;
    }

    getNotifications() {
      let notifications = [];
      if(this.props.getLatestMilestoneKey() === milestoneDefinitionKeys.DOELGROEP_ONGELDIG){
        notifications = [{
          id: this.props.dossier.id,
          title: this.context.gettext('Dienstverlening niet beschikbaar'),
          message: this.context.gettext('Deze dienstverlening is alleen beschikbaar voor burgers en ondernemingen. Gelieve in één van die hoedanigheden in te loggen om een aanvraag in te dienen.'),
          type: notificationTypes.ERROR
        }];
      }
      if(this.props.getLatestMilestoneKey() === milestoneDefinitionKeys.DUPLICAAT_DOSSIER){
        notifications = [{
          id: this.props.dossier.id,
          title: this.context.gettext('Er is al een andere aanvraag opgestart'),
          message: this.context.gettext('U moet eerst uw andere aanvraag indienen of annuleren om een nieuwe aanvraag op te starten.'),
          type: notificationTypes.ERROR
        }];
      }
      if(this.props.getLatestMilestoneKey() === milestoneDefinitionKeys.AANVRAAG_GEANNULEERD){
        notifications = [{
          id: this.props.dossier.id,
          title: this.context.gettext('Aanvraag geannuleerd'),
          message: this.context.gettext('U hebt deze aanvraag geannuleerd.'),
          type: notificationTypes.ERROR
        }];
      }
      if(this.props.getLatestMilestoneKey() === milestoneDefinitionKeys.AANVRAAG_AFGESLOTEN){
        notifications = [{
          id: this.props.dossier.id,
          title: this.context.gettext('Aanvraag automatisch afgesloten'),
          message: this.context.gettext('Uw aanvraag werd automatisch afgesloten omdat deze niet binnen 14 dagen werd ingediend.'),
          type: notificationTypes.ERROR
        }];
      }
      if(this.props.getLatestMilestoneKey() === milestoneDefinitionKeys.PVW_AANVRAAG_INGEDIEND){
        notifications = [{
          id: this.props.dossier.id,
          title: this.context.gettext('Aanvraag ontvangen'),
          message: this.context.gettext('Uw aanvraag werd ontvangen en wordt verwerkt. U kunt de vooruitgang raadplegen op de balk hierboven.'),
          type: notificationTypes.SUCCESS
        }];
      }
      if(this.props.getLatestMilestoneKey() === milestoneDefinitionKeys.EXTRA_INFO_NODIG && (this.props.getTask(taskDefinitionKeys.PVW_EXTRA_INFO_TAAK, true) !== undefined)){
        notifications = [{
          id: this.props.dossier.id,
          title: this.context.gettext('Aanvraag onvolledig'),
          message: this.context.gettext('Gelieve uw aanvraag aan te vullen met de volgende informatie: ').concat(this.props.getFieldFromDocument(documentDefinitionkeys.PVW_EXTRA_INFO, ['omschrijvenOntbrekendeInformatie', 'value'])),
          type: notificationTypes.WARNING
        }];
      }
      return notifications;
    }

    render() {
      // override some default props with props that are derived from latest version of the dossierDetails
      const componentsToPass = {
        ...this.props,
        contactInformation: this.state.contactInformation,
        dossierInformation: this.state.dossierInformation,
        downloads: this.state.downloads,
        notifications: this.state.notifications
      };

      return <CoreDossierPage { ...componentsToPass }/>;
    }
  }

  return mowPvwDossier;
}

export default coreDossierWrapper(mowPvwDossierWrapper());
